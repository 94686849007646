import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { AppTextLabel } from '@inwink/i18n';
import { registerMember, unregisterMember } from '@@modules/community/api/groups';
import { States } from '@@services/services';
import { Entities } from '@inwink/entities/entities';
import type { IGroupContext } from '@@modules/community/shell/group/communitygroupshell';
import type { IInwinkStore } from '@@store/index';

export interface IGroupRegistrationProps {
    community: States.ICommunityState
    communityGroupContext: IGroupContext
    store: IInwinkStore
    entityId: string
    i18nHelper?: Entities.i18nHelper
    user: States.IAppUserState
    page: States.ICurrentPageState
    isOutOfGroupContext?: boolean;
}

export function GroupRegistration({
    community,
    communityGroupContext,
    store,
    entityId,
    i18nHelper,
    user,
    page,
    isOutOfGroupContext
}: IGroupRegistrationProps) {
    const [registration, setRegistration] = React
        .useState<string>(communityGroupContext?.groupMember?.status?.toLowerCase());

    React.useEffect(() => {
        setRegistration(communityGroupContext?.groupMember?.status?.toLowerCase());
    }, [communityGroupContext?.groupMember]);

    React.useEffect(() => {
        const currentGroupMember = user?.currentUser?.member?.groupMembers
            ?.filter((groupMember) => groupMember?.group?.id === communityGroupContext?.group?.id)[0];
        setRegistration(currentGroupMember?.status?.toLowerCase());
    }, [user?.currentUser?.member?.groupMembers]);

    const isInvitedToGroup = () => {
        const currentMemberGroupMembers = user?.currentUser?.member?.groupMembers;
        const currentGroupMember = currentMemberGroupMembers
            ?.filter((groupMember) => groupMember?.group?.id === communityGroupContext?.group?.id);

        return currentGroupMember?.[0]?.status === "Invited";
    };

    const onClick = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();

        if (registration && registration !== "invited") {
            return unregisterMember(
                community.requestManagers.apiFront, entityId
            ).then((res: any) => {
                if (res) {
                    setRegistration("");
                    store.dispatch({
                        type: "USER_GROUPMEMBER_CHANGED",
                        payload: {
                            groupMembers: store.getState().user.currentUser.member.groupMembers
                                .filter((gm) => gm.group.id !== entityId)
                        }
                    });
                }
                if (communityGroupContext?.group?.registrationMode?.toLowerCase() === "private") {
                    window.location.reload();
                }
            }, (err) => {
                console.error("error while unregistrating", err);
            });
        }

        return import("@@community/features/user/useractions").then((mod) => {
            const targ = arg.target as HTMLElement;

            if ((communityGroupContext.group?.registrationMode === "Free" && user?.currentUser)
                || isInvitedToGroup()) {
                return mod.userActions.promptRequireMembership(i18nHelper, targ)(store.dispatch, store.getState)
                    .then((canAct) => {
                        if (canAct)
                            return processRegistration(null);
                    });
            }

            const handleJoinRequest = (groupMod: any) => {
                return groupMod.groupActions.showJoinRequest(
                    i18nHelper, null, entityId)(store.dispatch, store.getState)
                    .then((request: any) => {
                        if (request) {
                            return processRegistration(request?.text);
                        }
                    }).catch((err) => {
                        console.error("Error while processing join request", err);
                    });
            };

            return import("@@community/features/group/actions").then((groupMod) => {
                const target = arg.target as HTMLElement;
        
                if (isOutOfGroupContext && user?.currentUser !== null) {
                    return handleJoinRequest(groupMod);
                }
        
                return mod.userActions.promptUserAccess(i18nHelper, target)(store.dispatch, store.getState)
                    .then((canAct) => {
                        if (canAct) {
                            return handleJoinRequest(groupMod);
                        }
                    }).catch((err) => {
                        console.error("Error while prompting user access", err);
                    });
            }).catch((err) => {
                console.error("Error while importing group actions", err);
            });
        });
    };

    const processRegistration = (registerText?: string) => {
        return registerMember(
            community.requestManagers.apiFront, entityId, registerText
        ).then((res: any) => {
            setRegistration(res.status?.toLowerCase());
            store.dispatch({
                type: "USER_GROUPMEMBER_CHANGED",
                payload: {
                    groupMembers: [
                        ...store.getState().user.currentUser.member.groupMembers
                            .filter((gm) => gm.group.id !== entityId),
                        {
                            group: {
                                id: entityId,
                                title: page?.context.entity.title,
                                description: page?.context.entity.description
                            },
                            status: res.status
                        }
                    ],
                }
            });

        }, (err) => {
            console.error("error while registrating", err);
        });
    };

    const cannotRegisterOrUnregister = () => {
        const isGlobalModerator = user?.currentUser?.member?.membershipLevel?.configuration?.isModerator;
        const groupMember = user?.currentUser?.member?.groupMembers?.find(
            (gm) => communityGroupContext?.groupId === gm.group?.id
        );
        let isGroupModerator: boolean;
        let isInvited: boolean;
        let isRegistered: boolean;

        if (groupMember) {
            isGroupModerator = groupMember.isModerator;
            isInvited = groupMember.status === 'Invited';
            isRegistered = groupMember.status === 'Registered';
        }
        const isPrivate = communityGroupContext?.group?.registrationMode === 'Private';
        const isPublicGroup = communityGroupContext?.group?.allowPublicView;
        return (!user?.currentUser && !isPublicGroup) ||
            (isPrivate && !isInvited && !isRegistered) ||
            isGlobalModerator ||
            isGroupModerator;
    };

    if (cannotRegisterOrUnregister()) {
        return null;
    }

    const getStyleRegistrationButtonTrad = () => {
        if (registration && registration !== "registered" && registration !== "invited") {
            return "btnregistration checked";
        } else if (registration && registration === "registered") {
            return "btnregistered";
        }
        return "btnregistration";
    };

    const isRegistrationDisable = () => {
        return registration && registration !== "registered" && registration !== "invited";
    };

    const getRegistrationButtonTrad = () => {
        if (registration === "invited") {
            return "community.content.group.registration.button.unregistered";
        }
        return `community.content.group.registration.button.${registration || "unregistered"}`;
    };

    return <AsyncButton
        className={getStyleRegistrationButtonTrad()}
        disabled={isRegistrationDisable()}
        onClick={onClick}
    >
        <AppTextLabel
            i18n={getRegistrationButtonTrad()}
        />
    </AsyncButton>;
}
